import React from 'react';

export default class Buy extends React.Component {
    render() {
        return (
            <div id="buy" className="section">
                <h1 className="section-title">Waarom zou u bij ons skischoenen kopen?</h1>
                <div className="buy_content">
                    <p>
                        Wij staan erom bekend dat wij met kennis en kunde skischoenen kunnen aanmeten. Allereerst maken
                        wij een uitgebreide voetanalyse en nemen de wensen van de klant door. Vervolgens gaan wij op
                        zoek naar de best passende skischoen. Hiervoor hebben wij een ruime keuze van meer dan 70
                        modellen skischoenen verdeeld over zeven merken op voorraad. Wanneer deze skischoen is gevonden
                        kunnen wij deze perfectioneren. Dit kan door het vervormen van de skischoen of het toepassen
                        van bootfitting. Hierdoor worden alle druk- en pijnpunten uit de schoen weggenomen zodat deze
                        perfect zit. Bootfitting is altijd bij de prijs inbegrepen. Hiernaast wordt er vaak gekozen om
                        te werken met een andere inlegzool of Custom zool zodat de voet van onderaf goed wordt ondersteund
                    </p>
                    <p>
                        Wij geven een 100% garantie op aangekochte skischoenen. De klant kan middels een gratis skiles
                        de skischoenen uitproberen bij Indoorski & Snowboard Rotterdam. Zit de skischoen tijdens de les
                        niet goed kunnen wij deze kosteloos omruilen. Wanneer de skischoenen na een week skiën of
                        meerdere aanpassingen nog niet naar wens zit ruilen wij deze, tegen betaling van een huurbedrag,
                        om voor een ander product.  Wij geven niet op voordat u pijnloos kunt skiën.
                    </p>
                    <p>
                        Door een jarenlange ervaring in het adviseren van ski’s weten wij het juiste paar voor onze
                        klant erbij te pakken. Met meer dan 65 verschillende modellen ski’s verdeeld over 8 merken
                        hebben wij een ruime keuze. Alle ski’s die wij verkopen testen wij zorgvuldig op de piste zodat
                        we daadwerkelijk weten hoe deze skiet en wat de eigenschappen van de ski zijn. Bij aankoop van
                        ski’s nemen wij, als extra service, alle standaard onderhoudsbeurten van de ski voor onze rekening.
                    </p>
                    <p>
                        De ski’s worden op professionele wijze gemonteerd en afgesteld. Dit gebeurd machinaal bij ons
                        in de winkel. Bevallen de ski’s onverhoopt niet, dan mag u deze tegen een huurprijs omruilen
                        voor een ander product. Hierdoor geven wij ook op ski’s 100% garantie.
                    </p>
                </div>
            </div>
        )
    }
}
