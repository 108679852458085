import React from 'react';

import winter from '../img/zomer-aanbieding.png';
import huis from '../img/winter-huis.jpeg';

export default class Aanbiedingen extends React.Component {

    render() {
        return (
            <div id="aanbiedingen" className="section">
                <h1 className="section-title">Aanbiedingen</h1>

                <div className="aanbiedingen_wrapper">
                    <div className="aanbieding_card aanbieding_1">
                        <img className="aanbieding_img" alt="" src={winter} />

                        <p className="aanbieding_txt">
                            In de zomer één week wandelen, fietsen, golfen en genieten van de mooie natuur in Lofer vanaf
                            €350,- per week voor twee personen, een derde persoon betaald €10,- per dag, kinderen tot 15
                            jaar gratis. Gratis: pitpad, midgetgolf, toegang tot het buitenzwembad en binnenzwembad in
                            Unken, toegang tot gondel Lofer Alm en Klammen.
                        </p>
                    </div>

                    <div className="aanbieding_card aanbieding_2">
                        <img className="aanbieding_img" alt="" src={huis} />

                        <p className="aanbieding_txt">
                            In de winter hebben wij diverse appartementen beschikbaar in en rondom Lofer (Saalachtal).
                            U zit al vanaf €595,- exclusief ski pas 7 dagen in Haus Theresia.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}